<template>
<div>
  <div
    v-if="message.messagingUser"
    class="d-flex justify-space-between px-3 pt-4 pb-3 msg"
    :class="{ newMsg: !message.readByCurrentUser }"
    @click="$emit('open', { conversationId: message.conversationId, threadId:  message.threadId })"
  >
    <div class="d-flex justify-space-between">
      <StatusAvatar
        :userId="message.messagingUser.id"
        :size="30"
        showStatus
        class="mr-2"
      ></StatusAvatar>
      <div class="d-flex flex-column">
        <h5 v-if="message.messagingUser.fullName">{{ message.messagingUser.fullName | trimText(22) }}</h5>
        <span v-if="message.messagingUser.company" style="font-size: 10px">{{ message.messagingUser.company.name | trimText(22) }}</span>
        <span v-if="user && user.showChatDescription" class="d-inline-block text-truncate company">{{ user.chatDescription | trimText(30) }}</span>
        <v-spacer></v-spacer>
        <span style="font-size: 10px">
          {{ message.threadType | getDescription($store.getters.enums.conversationThreadType) }}
        </span>
        <span :class="{ 'font-italic': message.isMessageDeleted }" class="message" style="font-size: 9px">
          {{ message.isMessageDeleted ? '[' + $t('chatWindow.messageDeleted') + ']' : message.message | trimText(20) }}
        </span>
      </div>
    </div>
    <div class="d-flex flex-column">
      <span class="text-right" style="font-size: 10px">{{ message.dateOfLastMessage | dateFormat("DD.MM.YYYY") }}</span>
      <span class="text-right company">{{ message.dateOfLastMessage | dateFormat("HH:mm") }}</span>
      <v-spacer />
      <span class="align-self-end">
        <v-icon v-if="message.readByReceivers" color="accent" small>mdi-check-all</v-icon>
      </span>
    </div>
  </div>
</div>
</template>
<script>
import StatusAvatar from '@/components/StatusAvatar.vue'
export default {
  name: 'Message',
  props: ['message'],
  components: { StatusAvatar },
  computed: {
    user () {
      return this.$store.getters['users/users'].find((el) => el.id === this.message.messagingUser.id)
    }
  },
  methods: {}
}
</script>
<style>
.msg {
  position: relative;
  width: 100%;
  min-height: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.company {
  color: rgba(52, 58, 61, 0.6);
  font-size: 10px;
}
.msg h5 {
  font-weight: 600;
  color: var(--v-primary-base);
}
.msg:hover h5,
.msg:hover .message {
  color: var(--v-accent-base);
}
.msg:hover {
  color: white;
  background-color: var(--v-primary-base);
}
.msg:hover .company {
  color: white;
}
.newMsg {
  background-color: #E6E6E6;
}
.send {
  color: rgba(46, 88, 64, 0.2);
}
.msg:hover .v-avatar {
  border: solid thin white !important;
}
.msg:after {
  content: "";
  border-top: 2px solid #E6E6E6;
  bottom: 0px;
  left: 15px;
  width: calc(100% - 30px);
  position: absolute;
}
.msg:hover:after {
  display: none;
}
</style>
